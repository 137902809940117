import axios from "axios";
import qs from "qs";
import { Toast } from "vant";


class HttpRequest {
    constructor(baseUrl) {
        this.baseUrl = baseUrl;
        this.queue = {};
    }

    getInsideConfig() {
        const config = {
            baseURL: this.baseUrl,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            
            timeout: 30 * 1000,
        };
        return config;
    }

    destroy(url) {
        delete this.queue[url];
        if (!Object.keys(this.queue).length) {
            // Spin.hide()
        }
    }

    interceptors(instance, url, contentType) {
        // 请求拦截
        instance.interceptors.request.use(
            (config) => {
                config.data={...config.data,token:localStorage.getItem("token") || ''}
                
                // 添加全局的loading...
                if (!Object.keys(this.queue).length) {
                    // Spin.show() // 不建议开启，因为界面不友好
                }
                this.queue[url] = true;

                if (contentType !== "multipart/form-data") {

                    config.method === "post"
                        ? (config.data = qs.stringify({ ...config.data }))
                        : (config.params = { ...config.params });
                }

                if (contentType === 'application/json') {
                    config.headers['Content-Type'] = contentType
                    config.data = JSON.stringify(config.params)
                    config.params = ''
                }
                return config;
            },
            (error) => {
                return Promise.reject(error);
            }
        );

        // 响应拦截
        instance.interceptors.response.use(
            (res) => {
                this.destroy(url);
                const { data } = res;
                if (typeof data === "object") {
                    // 自己处理
                    // data = qs.stringify(data)
                }
                if (url.indexOf("login") >= 0 && data.code == 1) {
                    localStorage.setItem("token", data.data.userinfo.token);
                }
                return data;
            },
            (error) => {
                this.destroy(url);
                let errorInfo = error.response;
                if (!errorInfo) {
                    //   console.log(JSON.parse(JSON.stringify(error)));
                    //   const { request: { message, code }, config,} = JSON.parse(JSON.stringify(error));
                    const { message, code, config } = JSON.parse(JSON.stringify(error));

                    errorInfo = {
                        message,
                        code,
                        request: { responseURL: config.url },
                    };
                    error.message = "请求超时";
                    console.log("errorInfo", errorInfo);
                } else {
                    switch (error.response.status) {
                        case 400:
                            error.message = "错误请求";
                            break;
                        case 401:
                            error.message = "未授权，请重新登录";
                            localStorage.removeItem("token");
                            window.location.href="/";
                            break;
                        case 403:
                            error.message = "拒绝访问";
                            break;
                        case 404:
                            error.message = "请求错误,未找到该资源";
                            // window.location.href = "/NotFound";
                            break;
                        case 405:
                            error.message = "请求方法未允许";
                            break;
                        case 408:
                            error.message = "请求超时";
                            break;
                        case 500:
                            error.message = "努力加载中";
                            // error.message = "服务器端出错";
                            break;
                        case 501:
                            error.message = "网络未实现";
                            break;
                        case 502:
                            error.message = "网络错误";
                            break;
                        case 503:
                            error.message = "服务不可用";
                            break;
                        case 504:
                            error.message = "网络超时";
                            break;
                        case 505:
                            error.message = "http版本不支持该请求";
                            break;
                        default:
                            error.message = `连接错误${error.response.status}`;
                    }
                }
                Toast(error.message);
                return Promise.reject(error);
            }
        );
    }

    request(options) {
        const instance = axios.create();
        options = Object.assign(this.getInsideConfig(), options);
        this.interceptors(instance, options.url, options['Content-Type']);
        return instance(options);
    }
}
export default HttpRequest;
