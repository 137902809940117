import axios from "@/utils/request";


// 登录
export const login = (params) => {
    return axios.request({
        url: "/user/login",
        method: "post",
        data: params,
    });
};
// 快速登录
export const quicklogin=(params)=>{
    return axios.request({
        url: "/user/quicklogin",
        method: "post",
        data: params,
    });
}

// 完成阅读
export const doview=(params)=>{
    return axios.request({
        url:"/kpi/doview",
        method:"post",
        data:params
    })
}


export const index=(params)=>{
    return axios.request({
        url:'/kpi/index',
        method:"post",
        data:params
    })
}


export const indexforce=(params)=>{
    return axios.request({
        url:"/kpi/indexforce",
        method:"post",
        data:params
    })
}


export const doviewforce=(params)=>{
    return axios.request({
        url:"/kpi/doviewforce",
        method:"post",
        data:params
    })
}